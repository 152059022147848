<template>
  <!-- Error page-->
  <div>
    <nav-bar />
    <div class="misc-inner p-2 p-sm-3">
      <div class="misc-wrapper1 container">
        <div class="row text-justify">
          <div class="col-6">
            <h2 class="main-title w-100">Contact Us</h2>
            <p>Get in Touch With Us !</p>

            <div>
              <b-form
                @submit="onSubmit"
                @reset="onReset"
                class="row"
                id="contact-form"
              >
                <b-form-group
                  id="input-group-2"
                  label="Name:"
                  label-for="input-2"
                  class="col-sm-6"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.name"
                    placeholder="Enter name"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-1"
                  label="Email address:"
                  label-for="input-1"
                  class="col-sm-6"
                >
                  <b-form-input
                    id="input-1"
                    v-model="form.email"
                    type="email"
                    placeholder="Enter email"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Subject:"
                  label-for="input-2"
                  class="col-sm-12"
                >
                  <b-form-input
                    id="input-2"
                    v-model="form.subject"
                    placeholder="Your subject here"
                    required
                  ></b-form-input>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label="Message:"
                  label-for="input-2"
                  class="col-sm-12"
                >
                  <b-form-textarea
                    id="textarea"
                    v-model="form.message"
                    placeholder="Your message here"
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                </b-form-group>

                <b-form-group
                  id="input-group-2"
                  label-for="input-2"
                  class="col-sm-12"
                >
                  <b-button type="submit" variant="primary" block
                    >Send message
                  </b-button>
                </b-form-group>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import {
  BLink,
  BButton,
  BImg,
  BForm,
  BFormText,
  BFormInvalidFeedback,
  BFormValidFeedback,
  BFormDatalist,
  BFormTextarea,
} from "bootstrap-vue";
import store from "@/store/index";
const NavBar = () => import("@/components/NavBar.vue");
import { onUnmounted } from "@vue/composition-api";
import contractStoreModule from "./contractStoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BLink,
    BButton,
    BImg,
    NavBar,
    BForm,
    BFormText,
    BFormInvalidFeedback,
    BFormDatalist,
    BFormValidFeedback,
    BFormTextarea,
  },

  setup() {
    const CONTACT_APP_STORE_MODULE_NAME = "app-contact";

    // Register module
    if (!store.hasModule(CONTACT_APP_STORE_MODULE_NAME))
      store.registerModule(CONTACT_APP_STORE_MODULE_NAME, contractStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CONTACT_APP_STORE_MODULE_NAME))
        store.unregisterModule(CONTACT_APP_STORE_MODULE_NAME);
    });
  },
  data() {
    return {
      form: {
        email: "",
        name: "",
        subject: "",
        message: "",
      },
      show: true,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.$store
        .dispatch("app-contact/storeMessage", {
          data: this.form,
        })
        .then((res) => {
          console.log(res);
          if (res.status == "201") {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Message sent successfully",
                icon: "BellIcon",
                variant: "success",
                text: res.data.message,
              },
            });
            // this.$router.push("/settings");
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Oops! Message Failed",
                icon: "BellIcon",
                variant: "danger",
                text: res.data.message,
              },
            });
          }
        })
        .catch((error) => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: "Oops! Message Failed",
                icon: "BellIcon",
                variant: "danger",
                text: error,
              },
            });
        });
        this.onReset()
    },
    onReset(event) {
      event.preventDefault();
      this.form.email = "";
      this.form.name = "";
      this.form.subject = "";
      this.form.message = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
.brand-text {
  line-height: 42px;
  margin-bottom: 0;
}
</style>
